<template>
  <b-card>
    <global-services-table
      :services="mappedServices"
      :loading="loading"
      show-button
      show-search
      @button-click="onAddServiceClicked"
      @edit-service-click="onEditServiceClicked"
    />

    <!-- ADD SERVICE MODAL -->
    <b-modal
      :id="'add-global-service-modal'"
      title="Afegeix un servei"
      scrollable
      hide-footer
    >
      <global-service-form
        @cancel="onAddServiceCancel"
        @service-added="onServiceAdded"
        @add-service-error="onAddServiceError"
      />
    </b-modal>

    <!-- EDIT SERVICE MODAL -->
    <b-modal
      :id="'edit-global-service-modal'"
      title="Edita el servei"
      scrollable
      hide-footer
    >
      <global-service-form
        :global-service="selectedService"
        @cancel="onEditServiceCancel"
        @service-edited="onServiceEdited"
        @edit-service-error="onEditServiceError"
        @service-deleted="onServiceDeleted"
        @delete-service-error="onDeleteServiceError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BModal } from "bootstrap-vue";
import GlobalServicesTable from "@/views/global-services/list/components/GlobalServicesTable.vue";
import GlobalServiceForm from "@/views/global-services/list/components/GlobalServiceForm.vue";
import {
  getPaymentFrequencyName,
  getPaymentMethodName,
  getPaymentTimeName,
  getServiceName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BCard,
    GlobalServicesTable,
    BModal,
    GlobalServiceForm,
  },
  data() {
    return {
      selectedService: null,
    };
  },
  computed: {
    loading() {
      return this.loadingServices || this.loadingGlobalServices;
    },
    loadingServices() {
      return this.$store.getters["services/loading"];
    },
    servicesServices() {
      return this.$store.getters["services/services"];
    },
    loadingGlobalServices() {
      return this.$store.getters["globalServices/loading"];
    },
    globalServices() {
      return this.$store.getters["globalServices/services"];
    },
    mappedServices() {
      if (!this.globalServices.length) return [];
      return this.globalServices.map((globalService) => ({
        uuid: globalService.uuid || this.$t("No definit"),
        name:
          getServiceName(globalService.service, this.$i18n.locale) ||
          globalService.service.code,
        mandatory: globalService.mandatory ? "Sí" : "No",
        price: formatCurrency(globalService.price) || this.$t("No definit"),
        costPrice:
          formatCurrency(globalService.costPrice) || this.$t("No definit"),
        paymentFrequency:
          getPaymentFrequencyName(
            globalService.paymentFrequency,
            this.$i18n.locale
          ) || this.$t("No definit"),
        paymentTime:
          getPaymentTimeName(globalService.paymentTime, this.$i18n.locale) ||
          this.$t("No definit"),
        paymentMethods:
          this.getPaymentMethodsText(globalService.paymentMethods) ||
          this.$t("No definit"),
        amount: globalService.amount || this.$t("No definit"),
      }));
    },
  },
  created() {
    if (!this.loadingServices) this.fetchServices();
    if (!this.loadingGlobalServices) this.fetchGlobalServices();
  },
  methods: {
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els serveis"
          )
        );
    },
    fetchGlobalServices() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("globalServices/fetchServices")
        .catch(() => {
          // TODO: log error to Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    getPaymentMethodsText(paymentMethods) {
      if (!paymentMethods || paymentMethods.length === 0) return null;
      const text = [];
      paymentMethods.forEach((pm) => {
        if (pm) text.push(getPaymentMethodName(pm, this.$i18n.locale));
      });
      return text.join(", ");
    },
    onAddServiceClicked() {
      this.$bvModal.show("add-global-service-modal");
    },
    onAddServiceCancel() {
      this.$bvModal.hide("add-global-service-modal");
    },
    onServiceAdded() {
      this.fetchServices();
      this.$bvModal.hide("add-global-service-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditServiceCancel() {
      this.$bvModal.hide("edit-global-service-modal");
    },
    onServiceEdited() {
      this.fetchServices();
      this.$bvModal.hide("edit-global-service-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
    onServiceDeleted() {
      this.fetchServices();
      notifySuccess(
        "Servei eliminat",
        "El servei ha estat eliminat correctament"
      );
    },
    onDeleteServiceError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el servei"
      );
    },
    onEditServiceClicked(serviceUuid) {
      this.selectedService = this.globalServices.find(
        (s) => s.uuid === serviceUuid
      );
      this.$bvModal.show("edit-global-service-modal");
    },
  },
};
</script>
