<template>
  <div id="global-service-form">
    <!-- SERVICE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Servei</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="service"
              :options="servicesOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- CHARGABLE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>És obligatori?</h6>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox v-model="mandatory" switch inline />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Cobrem nosaltres?</h6>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="chargable"
              :disabled="paymentTime === 'PROVIDER'"
              switch
              inline
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PAYMENT FREQUENCY & TIME -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Freqüència de pagament</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="paymentFrequency"
              :options="paymentFrequencyOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Moment de pagament</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="paymentTime"
              :options="paymentTimeOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PAYMENT METHODS -->
    <b-row class="mb-1">
      <b-col cols="12" class="mb-25">
        <h6>Mètodes de pagament</h6>
      </b-col>
      <b-col
        v-for="option in paymentMethodOptions"
        :key="`payment-method-${option.value}-option`"
        cols="12"
        sm="6"
      >
        <b-form-checkbox
          v-model="paymentMethods"
          class="mb-1"
          :value="option.value"
        >
          {{ option.label }}
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- INVOICE HOLDER & VAT PERCENTAGE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Emissor de la factura <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="invoiceHolder"
              :options="invoiceHolderOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>% I.V.A.</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="vatPercentage"
              :options="vatPercentageOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PVP & COST PRICE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>PVP <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="pvpPrice" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Preu de cost <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="costPrice" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- COMISSION & AMOUNT -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Comissió <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="%">
              <b-form-input v-model="commissionPercentage" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Serveis disponibles <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-form-spinbutton v-model="amount" min="1" max="100" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="saveGlobalService">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  paymentFrequencyOptions,
  paymentTimeOptions,
  paymentMethodOptions,
  invoiceHolderOptions,
  vatPercentageOptions,
} from "@/utils/select-options";
import { getInitialServiceProps, getServiceName } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BFormSpinbutton,
    BButton,
  },
  props: {
    globalService: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      service: null,
      pvpPrice: null,
      costPrice: null,
      vatPercentage: null,
      commissionPercentage: null,
      invoiceHolder: null,
      mandatory: false,
      chargable: false,
      amount: null,
      paymentFrequency: null,
      paymentTime: null,
      paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER", "BIZUM"],
      vatPercentageOptions,
      invoiceHolderOptions,
      paymentFrequencyOptions,
      paymentTimeOptions,
      paymentMethodOptions,
    };
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
    servicesOptions() {
      if (!this.services.length) return [];

      const options = [];

      this.services
        .filter((service) => service.code !== "OTHER")
        .sort((a, b) => {
          if (a.description > b.description) return 1;
          if (b.description > a.description) return -1;
          return 0;
        })
        .forEach((service) => {
          options.push({
            label: getServiceName(service, this.$i18n.locale),
            value: service["@id"],
          });
        });

      const otherService = this.services.find(
        (service) => service.code === "OTHER"
      );

      if (otherService) {
        options.push({
          label: getServiceName(otherService, this.$i18n.locale),
          value: otherService["@id"],
        });
      }

      return options;
    },
  },
  watch: {
    paymentTime(value) {
      if (value === "PROVIDER") this.chargable = false;
    },
    invoiceHolder(value) {
      if (!value) this.invoiceHolder = null;
    },
    service(service) {
      if (this.globalService) return;

      const selectedService = this.services.find((s) => s["@id"] === service);
      if (!selectedService) {
        return;
      }

      this.initService(selectedService.code);
    },
  },
  created() {
    if (this.globalService) this.initForm();
  },
  methods: {
    initForm() {
      if (!this.globalService) return;
      this.service = this.globalService.service["@id"];
      this.pvpPrice = this.globalService.price / 100;
      this.costPrice = this.globalService.costPrice / 100;
      this.amount = this.globalService.amount;
      this.mandatory = this.globalService.mandatory || false;
      this.paymentFrequency = this.globalService.paymentFrequency;
      this.paymentTime = this.globalService.paymentTime;
      this.paymentMethods = this.globalService.paymentMethods;
      this.chargable = this.globalService.chargable || false;
      this.commissionPercentage = this.globalService.commissionPercentage;
      this.invoiceHolder = this.globalService.invoiceHolder;
      this.vatPercentage = this.globalService.vatPercentage;
    },
    initService(serviceCode) {
      const {
        mandatory,
        chargable,
        paymentFrequency,
        paymentTime,
        paymentMethods,
        pvp,
        costPrice,
        commissionPercentage,
        amount,
        invoiceHolder,
        vatPercentage,
      } = getInitialServiceProps(serviceCode) || {};

      this.mandatory = mandatory;
      this.chargable = chargable;
      this.paymentFrequency = paymentFrequency;
      this.paymentTime = paymentTime;
      this.paymentMethods = paymentMethods;
      this.pvpPrice = pvp;
      this.costPrice = costPrice;
      this.commissionPercentage = commissionPercentage;
      this.amount = amount;
      this.invoiceHolder = invoiceHolder;
      this.vatPercentage = vatPercentage;
    },
    resetForm() {
      this.service = null;
      this.pvpPrice = null;
      this.costPrice = null;
      this.amount = null;
      this.mandatory = false;
      this.paymentFrequency = null;
      this.paymentTime = null;
      this.paymentMethods = [];
      this.chargable = false;
      this.commissionPercentage = null;
      this.invoiceHolder = null;
      this.vatPercentage = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    saveGlobalService() {
      if (this.globalService) this.updateGlobalService();
      else this.createGlobalService();
    },
    createGlobalService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("globalServices/addService", {
            service: this.service,
            price: this.pvpPrice ? Math.round(this.pvpPrice * 100) : null,
            costPrice: this.costPrice ? Math.round(this.costPrice * 100) : null,
            amount: parseInt(this.amount, 10),
            mandatory: this.mandatory || false,
            paymentFrequency: this.paymentFrequency,
            paymentTime: this.paymentTime,
            paymentMethods: this.paymentMethods,
            chargable: this.chargable || false,
            invoiceHolder: this.invoiceHolder,
            commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
              ? null
              : parseFloat(this.commissionPercentage),
            vatPercentage: isNaN(parseFloat(this.vatPercentage))
              ? null
              : parseFloat(this.vatPercentage),
          })
          .then(() => this.$emit("service-added"))
          .catch(() => {
            this.$emit("add-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updateGlobalService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("globalServices/updateService", {
            uuid: this.globalService.uuid,
            service: this.service,
            price: this.pvpPrice ? Math.round(this.pvpPrice * 100) : null,
            costPrice: this.costPrice ? Math.round(this.costPrice * 100) : null,
            amount: parseInt(this.amount, 10),
            mandatory: this.mandatory || false,
            paymentFrequency: this.paymentFrequency,
            paymentTime: this.paymentTime,
            paymentMethods: this.paymentMethods,
            chargable: this.chargable || false,
            invoiceHolder: this.invoiceHolder,
            commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
              ? null
              : parseFloat(this.commissionPercentage),
            vatPercentage: isNaN(parseFloat(this.vatPercentage))
              ? null
              : parseFloat(this.vatPercentage),
          })
          .then(() => this.$emit("service-edited"))
          .catch(() => {
            this.$emit("edit-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
